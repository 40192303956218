import { AppConfig } from '@launchpad/types';

const config: Partial<AppConfig> = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'refresh-token',
  ignorePermissions: true,
  twoFa: true,
  policiesEnabled: true,
  pluginName: 'flik',
  disableUboDocumentUpload: true,
  disableCompanyDocumentUpload: true
};

export default config;
